<template>
  <BT-Blade-Item
    bladeName="dispatch-journey"
    :canDelete="false"
    :canSave="false"
    :changeToggle="changeToggle"
    ignorePermissions
    :loading="isLoading"
    navigation="journeys"
    :onPullSuccessAsync="pullJourney"
    title="Dispatch Journey">
    <template v-slot="{ item }">
      <BT-Snack v-model="msg" />

      <v-row>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Name</v-list-item-subtitle>
              <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-row>
            <v-col cols="6">
              <BT-Field-Address
                v-model="item.startLocation"
                label="Starting At" />
            </v-col>
            <v-col v-if="item.startLocationID != item.endLocationID" cols="6">
              <BT-Field-Address
                v-model="item.endLocation"
                label="Ending At" />
            </v-col>
          </v-row>

          <BT-Field-Date
            v-model="item.dueStartOn"
            label="Due Start On"
            shortDateAndTime />

          <BT-Field-Date
            v-if="item.startedOn != null"
            v-model="item.startedOn"
            label="Started On"
            shortDateAndTime />

          <BT-Field-Date
            v-if="item.dueEndOn != null"
            v-model="item.dueEndOn"
            label="Due End On"
            shortDateAndTime />

          <BT-Field-Date
            v-if="item.endedOn != null"
            v-model="item.endedOn"
            label="Ended On"
            shortDateAndTime />
        </v-col>
        <v-col cols="6">
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-subtitle>Packages</v-list-item-subtitle>
              <v-list-item-title>{{ item.lastPackages | toDisplayNumber }} / {{ item.maxPackages | toDisplayNumber }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-subtitle>Weight</v-list-item-subtitle>
              <v-list-item-title>{{ item.lastWeight | toDisplayNumber }} / {{ item.maxWeight | toDisplayNumber }} Kg</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-subtitle>Destinations</v-list-item-subtitle>
              <v-list-item-title>{{ item.destinationCount | toDisplayNumber }} / {{ item.maxDestinations | toDisplayNumber }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <BT-Blade-Button
            icon=""
            label="Release All Packages"
            @click="releaseAllPackages(item)" />
          
          <BT-Signature-Overlay
            block
            buttonClass="primary"
            color="white"
            fullscreen
            icon=""
            @ok="por => { release(item, por) }"
            text="Sign Release"
            transparent />

        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" md="6">
          <v-expansion-panels v-model="panelV">
            <BT-Blade-Expansion-Items
              addBladeName="dispatch-release"
              :canExportCSV="false"
              :canRefresh="false"
              :headers="[
                { text: 'Customer', value: 'buyer.companyName', title: 1 },
                { text: 'Status', value: 'status', display: true, subtitle: 1 }]"
              hideActions
              :items="item.movements"
              :onPullSuccessAsync="list => { return pullReleases(list, item); }"
              :onSelect="(b, release) => { currentRelease = release }"
              showTable
              title="Releases">
              <template v-slot:status="{ item }">
                {{ item.releasedOn != null ? 'Released' : 'Pending' }}
              </template>
            </BT-Blade-Expansion-Items>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" md="6">
          <v-expansion-panels v-model="panelVV">
            <BT-Blade-Expansion-Items
              :canExportCSV="false"
              :canRefresh="false"
              :canSelect="false"
              :headers="[
              { text: '', value: 'action', display: true, isIcon: true, width: 10 },
                { text: 'Released/Packed', value: 'qty', display: true, prefix: 'Rlsd/Pkd', subtitle: 1 },
                { text: 'Package', value: 'measurement', display: true, title: 1 }]"
              hideActions
              :items="filteredPackages"
              showTable
              title="All Packages">
              <template v-slot:action="{ item }">
                <v-btn
                  @click="releasePackage(item)"
                  icon
                  small>
                  <v-icon :class="item.quantityReleased == item.quantityPacked ? 'success--text' : 'warning--text'" small>mdi-check</v-icon>
                </v-btn>
              </template>
              <template v-slot:qty="{ item }">
                {{ item.quantityReleased | toDisplayNumber }} / {{ item.quantityPacked | toDisplayNumber }}
              </template>
              <template v-slot:measurement="{ item }">
                <BT-Entity 
                  navigation="measurements"
                  :itemValue="item.measurementID"
                  itemText="measurementName" />
              </template>
            </BT-Blade-Expansion-Items>
            <BT-Blade-Expansion-Items
              v-if="currentRelease != null"
              :canExportCSV="false"
              :canRefresh="false"
              :canSelect="false"
              :headers="[
                { text: '', value: 'action', display: true, isIcon: true, width: 10 },
                { text: 'Released/Packed', value: 'qty', display: true, prefix: 'Rlsd/Pkd', subtitle: 1 },
                { text: 'Package', value: 'measurement', display: true, title: 1 }]"
              hideActions
              :items="currentRelease.packagedItems"
              showTable
              :title="currentRelease != null ? `Packages: ${currentRelease.buyer.companyName}` : 'All Packages'">
              <template v-slot:action="{ item }">
                <v-btn
                  @click="item.quantityReleased = item.quantityPacked"
                  icon
                  small>
                  <v-icon :class="item.quantityReleased == item.quantityPacked ? 'success--text' : 'warning--text'" small>mdi-check</v-icon>
                </v-btn>
              </template>
              <template v-slot:actions="{ open }">
                <BT-Packages-Movement-Dialog
                    v-if="open && currentRelease != null"
                    v-model="currentRelease.packagedItems"
                    icon-only
                    packed
                    released
                    small
                    @ok="packages => { currentRelease.packagedItems = packages }" />
              </template>
              <template v-slot:qty="{ item }">
                <v-edit-dialog>
                  {{ item.quantityReleased | toDisplayNumber }} / {{ item.quantityPacked | toDisplayNumber }}
                  <template v-slot:input>
                    <BT-Number-Edit
                      v-model="item.quantityReleased"
                      label="Released" />
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:measurement="{ item }">
                <BT-Entity 
                  navigation="measurements"
                  :itemValue="item.measurementID"
                  itemText="measurementName" />
              </template>
            </BT-Blade-Expansion-Items>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </template>
  </BT-Blade-Item>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Dispatch-Journey',
    components: {
      BTPackagesMovementDialog: () => import('~components/BT-Packages-Movement-Dialog.vue'),
      BTSignatureOverlay: () => import("~components/BT-Signature-Overlay.vue")
    },
    data: function() {
      return {
        changeToggle: false,
        consignmentIDs: null,
        currentJourney: null,
        currentRelease: null,
        isLoading: false,
        msg: null,
        panelV: 0,
        panelVV: 0,
        panelIV: 0,
        panelVI: 0,
        proofOfRelease: null
      }
    },
    computed: {
      filteredPackages() {
        if (this.currentJourney != null) {
          return this.currentJourney.movements
            .filter(x => x.departureLocationID == this.currentJourney.startLocationID)
            .flatMap(x => x.packagedItems)
            .reduce(function(prev, curr) {
              var existing = prev.find(x => x.measurementID == curr.measurementID);

              if (existing != null) {
                existing.quantityPacked += curr.quantityPacked;
                existing.quantityReleased += curr.quantityReleased;
              }
              else {
                prev.push(Object.assign({}, curr));
              }
              
              return prev;
            }, []);
        }
        else {
          return [];
        }
      }
    },
    methods: {
      async pullConsignments(items, refresh) {
        var excessIDs = [];
        //var iter = 0;

        this.consignmentIDs = items.flatMap(x => x.value);

        excessIDs = this.consignmentIDs;
        //iter = 0;

        var results = [];

        try {
            if (this.isLengthyArray(excessIDs)) {
              //pull in sets of 20
              do {
                  var conIDs = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                  //iter += conIDs.length;
                  //this.loadingMsg = `${iter} of ${this.allConsignmentIDs.length} courier consignments`;
                  var cResults = this.copyDeep(await this.$BlitzIt.store.getAll('customer-consignments', { ids: conIDs.toString(), includeDetails: false, properties: 'ID,Buyer,ConsignmentNumber' }, refresh));
                  console.log(cResults);
                  cResults.forEach(consignment => {
                      //this.addOrUpdateCourierConsignment(consignment);
                      results.push(consignment);
                  })

                  excessIDs = excessIDs.filter(x => !conIDs.some(y => y == x));
              } while (this.isLengthyArray(excessIDs))
            }
          }
          catch (err) {
            this.msg = this.extractErrorDescription(err);
          }
          finally {
            this.loadingMsg = null;
          }

          results = results.map(x => Object.assign({}, x, { loadingCount: 0, errorMsg: null }));

          return results;
      },
      async pullJourney(item) {
        this.currentJourney = item;
        return item;
      },
      pullReleases(list, journey) {
        var l = list.filter(x => x.departureLocationID == journey.startLocationID);
        l.sort(firstBy(x => x.departureSortNumber).thenBy(x => x.buyer.companyName));
        return l;
      },
      releasePackage(pItem) {
        if (this.currentJourney != null && this.isLengthyArray(this.currentJourney.movements)) {
          this.currentJourney.movements.forEach(m => {
            m.packagedItems.forEach(p => {
              if (p.measurementID == pItem.measurementID) {
                p.quantityReleased = p.quantityPacked;
              }
            })
          })
        }
      },
      async release(journey, proof) {
        if (proof == null) {
          this.msg = 'No signature provided';
          return;
        }

        try {
          this.isLoading = true;

          var releasesToSave = journey.movements.filter(x => x.departureLocationID == journey.startLocationID && x.releasedOn == null);
        
          var total = releasesToSave.length;


          for (let i = 0; i < releasesToSave.length; i++) {
            const release = releasesToSave[i];
            this.msg = `Saving ${i + 1} of ${total} releases`;
            release.proofOfRelease = proof;

            var res = await this.$BlitzIt.store.patch('releases', release);
            
            release.rowVersion = res.rowVersion;
            release.releasedOn = res.releasedOn;
            release.releasedByUserID = res.releasedByUserID;
          
          }
        }
        catch (err) {
          this.msg = this.extractErrorDescription(err);
        }
        finally {
          this.isLoading = false;
        }
      },
      releaseAllPackages(journey) {
        var releasesToSave = journey.movements.filter(x => x.departureLocationID == journey.startLocationID && x.releasedOn == null);

        releasesToSave.forEach(r => {
          r.packagedItems.forEach(p => {
            p.quantityReleased = p.quantityPacked;
          })
        })
      },
      async saveRelease(release, proofOfRelease) {
        if (proofOfRelease == null) {
          this.msg = 'No signature provided';
          return;
        }

        try {
          this.msg = 'Saving Release';
          release.proofOfRelease = proofOfRelease;
          var res = await this.$BlitzIt.store.patch('releases', release);
          release.rowVersion = res.rowVersion;
          release.releasedOn = res.releasedOn;
          release.releasedByUserID = res.releasedByUserID;
        }
        catch (err) {
          this.msg = this.extractErrorDescription(err);
        }
        finally {
          this.loadingMsg = null;
        }
      }
    }
}
</script>